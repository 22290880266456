import styled from 'styled-components';

const Parents = ({ parents }) => (
  <ParentsContainer>
    <ParentsGroup>
      <Parent>{parents.bride.dad}</Parent>
      <Parent>{parents.bride.mom}</Parent>
    </ParentsGroup>
    <ParentsGroup>
      <Parent>{parents.groom.dad}</Parent>
      <Parent>{parents.groom.mom}</Parent>
    </ParentsGroup>
  </ParentsContainer>
);

export default Parents;

const ParentsContainer = styled.section``;

const ParentsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
`;

const Parent = styled.label`
  font-family: 'Poppins', sans-serif;
  line-height: 1.6rem;
  text-align: center;
`;
