import styled from 'styled-components';

const Place = ({ place, icon, image, imagePosition, imageFirst }) => (
  <PlaceContainer>
    <PlaceImage
      img={image}
      position={imagePosition}
      first={imageFirst}
    ></PlaceImage>
    <PlaceInfo>
      <PlaceTitle>{place.title}</PlaceTitle>
      <PlaceName>{place.name}</PlaceName>
      <PlaceAddress href={place.link} target="_blank">
        {place.address}
      </PlaceAddress>
      <PlaceTime>{place.time}</PlaceTime>
    </PlaceInfo>
  </PlaceContainer>
);

export default Place;

const PlaceContainer = styled.section`
  display: grid;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1024px) {
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const PlaceImage = styled.div`
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;

  @media screen and (min-width: 768px) {
    order: ${({ first }) => (first ? 0 : 1)};
  }

  @media screen and (min-width: 1024px) {
    background-position: ${({ position }) => position};
  }
`;

const PlaceTitle = styled.label`
  color: #636363;
`;

const PlaceInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  padding: 2rem 1rem;
`;

const PlaceName = styled.label`
  color: #434343;
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  font-weight: 900;
  line-height: 2.5rem;
`;

const PlaceAddress = styled.a`
  color: black;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

const PlaceTime = styled.label`
  font-size: 1.25rem;
  font-weight: bold;
`;
