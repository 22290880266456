import styled from 'styled-components';
import { BiChurch } from 'react-icons/bi';
import { GiPartyPopper } from 'react-icons/gi';
import { BrowserRouter } from 'react-router-dom';

import WeddingImg from './assets/place-1.jpg';
import ReceptionImg from './assets/place-2.jpg';
import Gallery1Img from './assets/gallery-1.jpg';
import Gallery2Img from './assets/gallery-2.jpg';
import ConfirmationImg from './assets/rsvp.jpg';
import Hero from './Hero';
import Intro from './Intro';
import Place from './Place';
import DressCode from './DressCode';
import Confirmation from './Confirmation';
import Gallery from './Gallery';
import Copyright from './Copyright';

const App = () => {
  const wedding = {
    title: 'CEREMONIA RELIGIOSA',
    name: 'Templo de San Luis Gonzaga',
    address: 'Miguel Hidalgo y Costilla 280, Centro, 64000 Monterrey, Nuevo León',
    time: '17:30 Horas',
    link: 'https://maps.app.goo.gl/YP1f9Gmcfkm6t4Ez7'
  };

  const reception = {
    title: 'RECEPCIÓN',
    name: 'Atrium Club Empresarial',
    address: 'Ignacio Zaragoza 1300, Centro, 64000 Monterrey, Nuevo León',
    time: '21:00 Horas',
    link: 'https://maps.app.goo.gl/Mq4Q3G5LdX3FFmZo7'
  };

  return (
    <BrowserRouter basename="/">
      <AppContainer>
        <Hero />
        <Intro />
        <Place
          place={wedding}
          icon={<BiChurch className="placeIcon" />}
          image={WeddingImg}
          imagePosition={'center -140px'}
          imageFirst={true}
        />
        <Place
          place={reception}
          icon={<GiPartyPopper className="placeIcon" />}
          image={ReceptionImg}
          imagePosition={'center -170px'}
          imageFirst={false}
        />
        <DressCode />
        <Confirmation img={ConfirmationImg} />
        <Gallery images={[Gallery1Img, Gallery2Img]}></Gallery>
        <Copyright />
      </AppContainer>
    </BrowserRouter>
  );
};

export default App;

const AppContainer = styled.main`
  overflow-x: hidden;
`;
