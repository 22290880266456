import styled from 'styled-components';

const Gallery = ({images}) => (
  <GalleryContainer>
    <GalleryImageLeft img={images[0]}></GalleryImageLeft>
    <GalleryImageRight img={images[1]}></GalleryImageRight>
  </GalleryContainer>
);

export default Gallery;

const GalleryContainer = styled.section`
  height: 250px;
  background-color: #ccc;
  display: flex;

  @media screen and (min-width: 1024px) {
    height: 450px;
  }
`;

const GalleryImageLeft = styled.div`
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 65%;
  height: 100%;
`;

const GalleryImageRight = styled.div`
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 35%;
  height: 100%;
`;
