import styled from 'styled-components';

const Copyright = () => (
  <CopyrightContainer>
    <CopyrightWrapper>
      <CopyrightText>
        © {new Date().getFullYear()} All Rights Reserved
      </CopyrightText>
      <CopyrightText>
        Hecho por{' '}
        <a href="https://wa.me/528115137432?text=Hola!%20Quisiera%20información%20acerca%20de%20las%20invitaciones%20digitales.">
          Corchete Digital
        </a>
      </CopyrightText>
    </CopyrightWrapper>
  </CopyrightContainer>
);

export default Copyright;

const CopyrightContainer = styled.section`
  display: flex;
  justify-content: center;
`;

const CopyrightWrapper = styled.div`
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
`;

const CopyrightText = styled.label`
  color: #b4b4b4;
  font-size: 0.85rem;

  a {
    color: black;
  }
`;
