import styled from 'styled-components';

import DressCodeImg from './assets/dress-code.jpg';

const DressCode = ({ dressCode }) => (
  <DressCodeContainer>
    <DressCodeOverlay />
    <DressCodeWrapper>
      <DressCodeTitle>CÓDIGO DE VESTIMENTA</DressCodeTitle>
      <DressCodeInfo>
        <DressCodeLabel>
          Caballeros | <span>Traje o smoking</span>
        </DressCodeLabel>
        <DressCodeLabel>
          Damas | <span>Vestido formal</span>
        </DressCodeLabel>
      </DressCodeInfo>
    </DressCodeWrapper>
  </DressCodeContainer>
);

export default DressCode;

const DressCodeContainer = styled.section`
  align-items: flex-end;
  background-image: url(${DressCodeImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #f0f0f0;
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  height: 500px;
  position: relative;

  @media screen and (min-width: 1024px) {
    background-position: center -90px;
  }
`;

const DressCodeOverlay = styled.div`
  background-color: black;
  position: absolute;
  opacity: 0.5;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
`;

const DressCodeWrapper = styled.div`
  text-align: center;
  margin-bottom: 200px;
  z-index: 2;

  @media screen and (min-width: 1440px) {
    margin-bottom: 70px;
  }
`;

const DressCodeTitle = styled.label`
  color: #ffffff;
`;

const DressCodeInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
`;

const DressCodeLabel = styled.label`
  color: #ffffff;

  span {
    font-size: 1rem;
    font-weight: bold;
  }
`;
