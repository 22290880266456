import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const calculateTimeLeft = (date) => {
  let difference = +new Date(date) - +new Date();

  let timeLeft = {
    Días: 0,
    Horas: 0,
    'Min.': 0,
    'Seg.': 0,
  };

  if (difference > 0) {
    timeLeft = {
      Días: Math.floor(difference / (1000 * 60 * 60 * 24)),
      Horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
      'Min.': Math.floor((difference / 1000 / 60) % 60),
      'Seg.': Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const Counter = ({ date }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(date));
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <CounterContainer>
      {Object.keys(timeLeft).map((interval) => {
        return (
          <CounterBlock key={interval}>
            <span>{timeLeft[interval]}</span>
            <span>{interval}</span>
          </CounterBlock>
        );
      })}
    </CounterContainer>
  );
};

export default Counter;

const CounterContainer = styled.section`
  color: white;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem;
  gap: 2.5rem;
`;

const CounterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;
