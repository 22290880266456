import styled from 'styled-components';

import Counter from './Counter';
import heroBanner from './assets/hero-banner.jpg';

const Hero = () => (
  <HeroContainer img={heroBanner}>
    <HeroTopWrapper>
      <HeroPre>NOS CASAMOS</HeroPre>
      <HeroTitle>Daniela & Gerardo</HeroTitle>
      <HeroSubtitle>25 de Octubre de 2024</HeroSubtitle>
    </HeroTopWrapper>
    <HeroBottomWrapper>
      <Counter date="10/25/2024"></Counter>
    </HeroBottomWrapper>
  </HeroContainer>
);

export default Hero;

const HeroContainer = styled.header`
  align-items: center;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-around;
  position: relative;
  width: 100vw;

  &:after {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const HeroTopWrapper = styled.section`
  text-align: center;
  z-index: 1;
`;

const HeroPre = styled.label`
  align-items: center;
  color: white;
  display: flex;
  /* font-family: 'Libre Baskerville', serif; */
  font-family: 'Poppins', sans-serif;
  font-size: 1.3rem;
  font-weight: 300;
  justify-content: space-evenly;
  letter-spacing: 0.5rem;
  margin-bottom: 5px;

  &:before,
  &:after {
    background-color: white;
    content: '';
    height: 1px;
    width: 50px;
  }
`;

const HeroTitle = styled.h1`
  color: white;
  font-family: 'Alex Brush', cursive;
  font-size: 3.25rem;
  font-weight: bold;
  line-height: normal;
  margin: 0;

  @media screen and (min-width: 768px) {
    font-size: 4.5rem;
  }
`;

const HeroSubtitle = styled.h2`
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  margin: 0;
`;

const HeroBottomWrapper = styled.section`
  z-index: 1;
`;
