import styled from 'styled-components';

import { BsEnvelope } from 'react-icons/bs';

const Confirmation = ({ img }) => (
  <ConfirmationContainer>
    <ConfirmationWrapper>
      <ConfirmationImageWrapper img={img}></ConfirmationImageWrapper>
      <ConfirmationTextWrapper>
        <ConfirmationTitle>RSVP</ConfirmationTitle>
        <ConfirmationQuestion>¿Contamos contigo?</ConfirmationQuestion>
        <ConfirmationDescription>
          Será un honor que nos acompañes a compartir este momento tan esperado
          para nosotros.
        </ConfirmationDescription>
        <ConfirmationDescription>
          Ya sea que puedas asistir o no, tu confirmación es sencilla pero
          valiosa demostración de aprecio, respeto y gratitud.
        </ConfirmationDescription>
        <ConfirmationDescription>
          Agradeceremos tu confirmación antes del{' '}
          <span>20 de Septiembre de 2024</span>
        </ConfirmationDescription>
        <ConfirmationButton href='https://wa.me/528182753910?text=Hola!%20Quiero%20confirmar%20mi%20asistencia%20a%20la%20boda%20de%20Daniela%20y%20Gerardo.%20Confirmo%20por%20los%20siguientes%20nombres%20(favor%20de%20compartir%20nombres%20completos%20de%20la%20cantidad%20de%20lugares%20asignados):%20'>
          Confirmar
        </ConfirmationButton>
        <ConfirmationDescription>
          Recuerda que nuestra boda es: <span>NO Niños</span>
        </ConfirmationDescription>
        <GiftSuggestion>
          Sugerencia de regalo
          <GiftSuggestionContent>
            <BsEnvelope className='icon' />
            <a href='https://mesaderegalos.liverpool.com.mx/milistaderegalos/51486248' target='_blank'>Mesa de regalos (Liverpool)</a>
          </GiftSuggestionContent>
        </GiftSuggestion>
      </ConfirmationTextWrapper>
    </ConfirmationWrapper>
  </ConfirmationContainer>
);

export default Confirmation;

const ConfirmationContainer = styled.section`
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1440px) {
    height: 400px;
  }
`;

const ConfirmationWrapper = styled.section`
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1440px) {
    flex-direction: row;
  }
`;

const ConfirmationTextWrapper = styled.div`
  padding: 2rem 1rem;
  width: 100%;
  order: 1;

  @media screen and (min-width: 1440px) {
    order: 2;
    width: 50%;
  }
`;

const ConfirmationImageWrapper = styled.div`
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
  background-position: center;
  order: 2;

  @media screen and (min-width: 1440px) {
    width: 50%;
    height: auto;
    order: 1;
    background-position: center;
  }
`;

const ConfirmationTitle = styled.label`
  color: #636363;
  display: block;
`;

const ConfirmationQuestion = styled.label`
  color: #eaa782;
  display: block;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 5px;
`;

const ConfirmationDescription = styled.label`
  display: block;
  margin-bottom: 10px;

  span {
    font-weight: bold;
  }
`;

const ConfirmationButton = styled.a`
  border: 1px solid #eaa782;
  color: black;
  display: inline-block;
  margin: 15px 0;
  padding: 1rem;
  text-decoration: none;

  &:hover {
    background-color: #eaa782;
    color: white;
  }
`;

const GiftSuggestion = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  .icon {
    font-size: 1.25rem;
    margin-top: 4px;
    margin-right: 15px;
  }
`;

const GiftSuggestionContent = styled.div`
  display: flex;
  align-items: end;
`;
