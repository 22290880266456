import styled from 'styled-components';

import Parents from './Parents';

const Intro = () => {
  const parents = {
    bride: {
      mom: 'Bertha Morales Salazar',
      dad: 'Gerardo Javier González Cantu'
    },
    groom: {
      mom: 'Susana Lozano Tadeo',
      dad: 'Alfredo De La Garza González (†)'
    }
  };

  return (
    <IntroContainer>
      <IntroWrapper>
        <IntroTitle>CON LA BENDICIÓN DE DIOS Y DE NUESTROS PADRES</IntroTitle>
        <Parents parents={parents}></Parents>
        <IntroSubtitle>
          Tenemos el honor de invitarlos a festejar la unión de nuestras vidas
          en matrimonio el día
        </IntroSubtitle>
        <IntroWeddingDay>
          VIERNES 25 <span>Octubre</span> 2024
        </IntroWeddingDay>
      </IntroWrapper>
    </IntroContainer>
  );
};

export default Intro;

const IntroContainer = styled.section`
  display: flex;
  font-family: 'Poppins', sans-serif;
  justify-content: center;
`;

const IntroWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 5rem 1rem;
  width: 100%;
`;

const IntroTitle = styled.h3`
  font-size: 1.2rem;
  margin-top: 0;
  text-align: center;
`;

const IntroSubtitle = styled.h3`
  font-size: 1rem;
  text-align: center;
`;

const IntroWeddingDay = styled.label`
  font-size: 1.5rem;
  margin-top: 1.2rem;

  span {
    font-family: 'Alex Brush', cursive;
    font-size: 2rem;
    font-weight: bold;
  }
`;
